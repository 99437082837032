import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const InfoFooter = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto text-center">
        <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-6">
          <div className="flex items-center">
            <FaPhoneAlt className="mr-2 text-primary" />
            <span>01-5244670 | 01-5244670</span>
          </div>
          <div className="flex items-center">
            <FaEnvelope className="mr-2 text-primary" />
            <span>info@uranustechnepal.com</span>
          </div>
          <div className="flex items-center">
            <FaMapMarkerAlt className="mr-2 text-primary" />
            <span>Bijulibazar-10, Kathmandu, Nepal</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default InfoFooter;
