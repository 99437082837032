import React from "react";
import CoreBankAnimation from "../animation_component/CoreBankAnimation";
import WebAnimation from "../animation_component/WebAnimation";
import AppAnimation from "../animation_component/AppAnimation";
import CloudAnimation from "../animation_component/CloudAnimation";
import ConsultingAnimation from "../animation_component/ConsultingAnimation";
import SmsAnimation from "../animation_component/SmsAnimation";
import UIUXAnimation from "../animation_component/UI_UX_Animation";

const services = [
  {
    component: <CoreBankAnimation />,
    title: "Core Banking Solution",
    description:
      "Uranus Solution for Banks, Microcredit, Microfinance Institutions, Credit Unions, and Cooperative",
  },
  {
    component: <WebAnimation />,
    title: "Web Development",
    description: "Custom website development tailored to your business needs.",
  },
  {
    component: <AppAnimation />,
    title: "Mobile App Development",
    description: "High-performance mobile applications for Android and iOS.",
  },
  {
    component: <SmsAnimation />,
    title: "Bulk SMS",
    description: "Reliable and cost-effective bulk SMS services.",
  },
  {
    component: <CloudAnimation />,
    title: "Cloud Services",
    description:
      "Scalable Uranus cloud solutions for storage and computing needs.",
  },
  {
    component: <ConsultingAnimation />,
    title: "IT Consulting",
    description:
      "Expert advice for optimizing your IT infrastructure and strategy.",
  },
  {
    component: <UIUXAnimation />,
    title: "UI/UX Design",
    description: "Crafting intuitive and visually appealing designs.",
  },
];

const Service = () => {
  return (
    <div className="mt-10 px-4 md:px-10 lg:px-20 py-10">
      <h1 className="font-semibold text-primarytext text-3xl text-center mb-2">
        Our Services
      </h1>
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        Providing innovative and reliable tech solutions for your business{" "}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {services.map((service, index) => (
          <div
            key={index}
            className="shadow-lg px-5 py-6 flex flex-col bg-white rounded-lg transform transition-transform duration-300 hover:scale-105 hover:bg-gradient-to-r from-[#e0f7f8] via-white to-[#e0f7f8]"
          >
            <div className="mb-4">{service.component}</div>
            <h2 className="text-primary text-center font-semibold text-lg md:text-xl mb-2">
              {service.title}
            </h2>
            <p className="text-gray-600 font-light text-center text-sm md:text-base">
              {service.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
