import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { getFeedbackApi } from "../api/Api";
import Lottie from "react-lottie";
import assetImage from "../assets/logo.png";
import errorAnimation from "../assets/animation/error_connection.json";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        setLoading(true);
        const response = await getFeedbackApi();
        setTestimonials(response.data.data || []);
        setLoading(false);
      } catch (err) {
        setError("Failed to load testimonials.");
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="flex justify-center mb-2">
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FaStar key={i} className="text-yellow-500" />
          ))}
        {halfStar && <FaStarHalfAlt className="text-yellow-500" />}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FaRegStar key={i} className="text-yellow-500" />
          ))}
      </div>
    );
  };

  const getInitials = (name) => {
    if (!name) return "N/A";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="mt-10 px-4 md:px-10 lg:px-20 py-10">
      <h1 className="font-semibold text-primarytext text-3xl text-center mb-2">
        Client Feedback
      </h1>
      <p className="text-center text-gray-600 mb-4">
        The commitment and excellence of our services are much appreciated by
        our clients. Their feedback on us is this.
      </p>

      {loading && (
        <div className="text-center text-gray-600">Loading testimonials...</div>
      )}

      {error && (
        <div className="flex flex-col items-center">
          <Lottie options={defaultOptions} height={150} width={150} />
          <div className="text-center text-red-500 mt-4">{error}</div>
        </div>
      )}

      {!loading && !error && testimonials.length === 0 && (
        <div className="text-center text-gray-600">
          No testimonials available.
        </div>
      )}

      {!loading && !error && testimonials.length > 0 && (
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          showDots={true}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow p-6 flex flex-col items-center text-center">
                <img
                  src={testimonial.imageUrl || assetImage}
                  alt={
                    testimonial.name ? getInitials(testimonial.name) : "Client"
                  }
                  className="w-24 h-24 rounded-full mb-4 object-cover"
                />
                <p className="text-lg text-gray-700 mb-4">
                  "{testimonial.feedback}"
                </p>
                <h3 className="text-primary font-semibold text-xl">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500 mb-2">
                  {testimonial.designation || "Client"}
                </p>
                {renderStars(testimonial.rating)}
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default Testimonials;
