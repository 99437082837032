import React from "react";
import { motion } from "framer-motion";
import { FaHandsHelping, FaEye, FaIndustry } from "react-icons/fa";
import useDocumentTitle from "../components/DocTitle";
import ceoimg from "../assets/ceo_ura.png";
import cooimg from "../assets/nirajan.png";
import InfoFooter from "../components/InfoFooter"; // Import the Footer component

const AboutUs = () => {
  useDocumentTitle("About Us - URANUS TECH NEPAL");

  const visionColor = "#FF8C00";
  const expertiseColor = "#1E90FF";
  const commitmentColor = "#32CD32";

  const cardStyle =
    "text-left shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex items-center";
  const iconStyle = "text-8xl mr-6";

  return (
    <div className="min-h-screen flex flex-col">
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10 flex-grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
        >
          About Us
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          Welcome to Uranus Tech Nepal. We are a team of passionate
          professionals dedicated to delivering top-notch technological
          solutions. Our mission is to innovate and provide exceptional services
          that drive success for our clients.
        </motion.p>

        <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            className={cardStyle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05 }}
          >
            <FaEye className={iconStyle} style={{ color: visionColor }} />
            <div>
              <h2
                className="font-semibold text-xl md:text-2xl mb-2"
                style={{ color: visionColor }}
              >
                Our Vision
              </h2>
              <p className="text-gray-700">
                To empower businesses with innovative technology solutions that
                foster growth, efficiency, and sustainability. We envision a
                future where every business, regardless of size, has access to
                cutting-edge technology that drives success and creates lasting
                impact.
              </p>
            </div>
          </motion.div>

          <motion.div
            className={cardStyle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05 }}
          >
            <FaIndustry
              className={iconStyle}
              style={{ color: expertiseColor }}
            />
            <div>
              <h3
                className="font-semibold text-xl md:text-2xl mb-2"
                style={{ color: expertiseColor }}
              >
                Our Expertise
              </h3>
              <p className="text-gray-700">
                We specialize in Core Banking Solutions, Web Development, Mobile
                App Development, IT Consulting, and more. Our team of experts is
                dedicated to delivering customized solutions that cater to the
                specific needs of our clients, ensuring they stay ahead in the
                competitive market.
              </p>
            </div>
          </motion.div>

          <motion.div
            className={cardStyle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05 }}
          >
            <FaHandsHelping
              className={iconStyle}
              style={{ color: commitmentColor }}
            />
            <div>
              <h3
                className="font-semibold text-xl md:text-2xl mb-2"
                style={{ color: commitmentColor }}
              >
                Our Commitment
              </h3>
              <p className="text-gray-700">
                We are committed to delivering quality and ensuring customer
                satisfaction, with a focus on innovation, integrity, and
                excellence in all our services. Our commitment extends beyond
                project delivery; we aim to build long-term relationships with
                our clients based on trust and mutual growth.
              </p>
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          {/* CEO Card */}
          <motion.div
            className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row items-center"
            whileHover={{ scale: 1.05 }}
          >
            <motion.img
              src={ceoimg}
              alt="CEO"
              className="w-32 h-32 md:w-48 md:h-48 rounded-full mb-4 md:mb-0 md:mr-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            />
            <div>
              <h3 className="text-2xl font-bold text-secondaryText mb-2">
                Mr. Nirajan Subedi
              </h3>
              <h4 className="text-lg text-gray-600 mb-4">
                Executive Director of Uranus Tech Nepal
              </h4>
              <p className="italic text-gray-600 mb-4">
                "At Uranus Tech Nepal, we believe that innovation and customer
                satisfaction go hand in hand. Our dedicated team is committed to
                delivering solutions that not only meet but exceed expectations.
                We are always looking ahead, anticipating challenges, and
                finding new ways to create value for our clients. Thank you for
                trusting us with your technological needs."
              </p>
            </div>
          </motion.div>

          {/* COO Card */}
          <motion.div
            className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row items-center"
            whileHover={{ scale: 1.05 }}
          >
            <motion.img
              src={cooimg}
              alt="COO"
              className="w-32 h-32 md:w-48 md:h-48 rounded-full mb-4 md:mb-0 md:mr-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            />
            <div>
              <h3 className="text-2xl font-bold text-secondaryText mb-2">
                Mr. Homnath Rijal
              </h3>
              <h4 className="text-lg text-gray-600 mb-4">
                COO & Business Development Officer of Uranus Tech Nepal
              </h4>
              <p className="italic text-gray-600 mb-4">
                "Our focus on operational excellence ensures that we
                consistently deliver on our promises. At Uranus Tech Nepal, we
                are committed to driving efficiency and fostering innovation
                across all aspects of our business. By aligning our operations
                with our clients' goals, we create strategies that not only meet
                but exceed expectations."
              </p>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
      <InfoFooter />
    </div>
  );
};

export default AboutUs;
