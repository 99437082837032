import React, { useContext, useEffect } from "react";
import { FaLinkedin, FaTwitter, FaGithub } from "react-icons/fa";
import { motion } from "framer-motion";
import StaffContext from "../context/teamContext";
import useDocumentTitle from "../components/DocTitle";
import InfoFooter from "../components/InfoFooter";

const OurTeam = () => {
  useDocumentTitle("Meet Our Team - URANUS TECH NEPAL ");
  const { staff, getAllStaff } = useContext(StaffContext);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        await getAllStaff();
      } catch (error) {
        console.error(`Error fetching staff: ${error}`);
      }
    };
    fetchStaff();
  }, [getAllStaff]);

  // Function to group staff by designation
  const groupByDesignation = (staffList) => {
    return staffList.reduce((acc, member) => {
      if (!acc[member.designation]) {
        acc[member.designation] = [];
      }
      acc[member.designation].push(member);
      return acc;
    }, {});
  };

  const groupedStaff = groupByDesignation(staff);

  // Function to get initials from a name
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((part) => part[0])
      .join("");
  };

  return (
    <div className="min-h-screen flex flex-col">
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10 flex-grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          Meet Our Team
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 mb-10 text-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: "easeOut" }}
        >
          Our team is composed of talented and dedicated professionals who are
          passionate about delivering exceptional results.
        </motion.p>
        {Object.keys(groupedStaff).length > 0 ? (
          Object.keys(groupedStaff).map((designation) => (
            <motion.div
              key={designation}
              className="mb-8"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <motion.h2
                className="font-bold text-2xl text-primarytext mb-4 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
              >
                {designation}
              </motion.h2>
              <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ staggerChildren: 0.2, delayChildren: 0.2 }}
              >
                {groupedStaff[designation].map((member) => (
                  <motion.div
                    key={member._id}
                    className="shadow-lg rounded-lg overflow-hidden bg-white p-4 text-center relative"
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7, ease: "easeOut" }}
                  >
                    <div className="w-20 h-20 rounded-full mx-auto mb-4 object-cover border-4 border-primary flex items-center justify-center bg-gray-200">
                      <img
                        src={`/${member.image}`}
                        alt={member.fullName}
                        className="rounded-full w-full h-full"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.style.display = "none";
                          e.target.parentElement.innerHTML = `<span class="text-primary text-2xl">${getInitials(
                            member.fullName
                          )}</span>`;
                        }}
                      />
                    </div>
                    <h3 className="text-lg font-semibold text-primary mb-2">
                      {member.fullName}
                    </h3>
                    <p className="text-sm text-gray-600 mb-4">{member.role}</p>
                    <div className="flex justify-center space-x-4 mt-4">
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="text-blue-700 text-xl hover:text-blue-500 transition-colors" />
                      </a>
                      <a
                        href={member.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter className="text-blue-500 text-xl hover:text-blue-300 transition-colors" />
                      </a>
                      <a
                        href={member.github || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaGithub className="text-gray-900 text-xl hover:text-gray-700 transition-colors" />
                      </a>
                    </div>
                    <div className="absolute inset-0 bg-white bg-opacity-90 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center text-gray-900 p-4">
                      <p className="text-sm">{member.description}</p>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ))
        ) : (
          <motion.p
            className="text-center text-gray-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          >
            No Data Available
          </motion.p>
        )}
      </motion.div>
      <InfoFooter />
    </div>
  );
};

export default OurTeam;
