import React, { useState } from "react";
import { loginApi } from "../api/Api";
import toast from "react-hot-toast";
import {
  FaGoogle,
  FaFacebookF,
  FaApple,
  FaEnvelope,
  FaPhoneAlt,
  FaLocationArrow,
} from "react-icons/fa";
import logo from "../assets/logo.png";
import AlertDialog from "./AlertDialog";
import useDocumentTitle from "./DocTitle";

const LoginPage = () => {
  useDocumentTitle("Login - URANUS TECH NEPAL");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const validateInput = () => {
    let valid = true;
    setEmailError("");
    setPasswordError("");

    if (email.trim() === "") {
      setEmailError("Email is required");
      valid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Password is required");
      valid = false;
    }

    return valid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateInput()) return;

    console.log("Email:", email);
    console.log("Password:", password);

    try {
      // Attempt to login by calling the API
      const response = await loginApi(email, password);

      // If successful, store the token and show a success message
      localStorage.setItem("token", response.data.token);
      toast.success("Login successful!");
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Login error:", error.response?.data?.message);

      toast.error(error.response?.data?.message || "Login failed.");
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full bg-white bg-opacity-90 shadow-xl rounded-lg p-8">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>

        <h2 className="text-center text-3xl font-bold text-gray-800">
          Welcome Back!
        </h2>
        <p className="text-center text-sm text-gray-600 mt-2">
          Don't have an account?{" "}
          <a
            href="/register"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Sign up
          </a>
        </p>

        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`appearance-none rounded-t-md relative block w-full px-3 py-2 border ${
                  emailError ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm`}
                placeholder="Email"
              />
              {emailError && (
                <p className="text-red-500 text-xs mt-1">{emailError}</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`appearance-none rounded-b-md relative block w-full px-3 py-2 border ${
                  passwordError ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm`}
                placeholder="Password"
              />
              {passwordError && (
                <p className="text-red-500 text-xs mt-1">{passwordError}</p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition ease-in-out duration-150"
            >
              Sign In
            </button>
          </div>
        </form>

        <div className="mt-6 flex items-center justify-between">
          <hr className="flex-grow border-gray-300" />
          <span className="px-3 text-gray-500 text-sm whitespace-nowrap">
            or continue with
          </span>
          <hr className="flex-grow border-gray-300" />
        </div>

        <div className="mt-6 flex justify-around">
          <button
            className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 transition ease-in-out duration-150"
            onClick={handleOpenDialog}
          >
            <FaGoogle className="w-6 h-6 text-red-500" />
          </button>
          <button
            className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 transition ease-in-out duration-150"
            onClick={handleOpenDialog}
          >
            <FaFacebookF className="w-6 h-6 text-blue-600" />
          </button>
          <button
            className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white hover:bg-gray-50 transition ease-in-out duration-150"
            onClick={handleOpenDialog}
          >
            <FaApple className="w-6 h-6 text-black" />
          </button>
        </div>

        <div className="mt-8 text-center text-sm text-gray-600">
          <p className="flex items-center justify-center">
            <FaPhoneAlt className="mr-2" /> 01-5244670
            <span className="mx-4">|</span> <FaEnvelope className="mr-2" />{" "}
            info@uranustechnepal.com
          </p>
          <p className="flex items-center justify-center mt-2">
            <FaLocationArrow className="mr-2" />
            Bijulibazar-10, Kathmandu, Nepal
          </p>
        </div>
      </div>

      {isDialogOpen && (
        <AlertDialog
          message="This feature is not available. Check back later."
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

export default LoginPage;
