import React from "react";
import smsAnimationData from "../assets/animation/ui_ux.json";
import Lottie from "react-lottie";

const UIUXAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: smsAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="hidden relative md:block w-full max-w-[200px]  sm:max-w-[200px] xs:max-w-[200px] mx-auto">
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  );
};

export default UIUXAnimation;
