import React, { useState } from "react";
import { motion } from "framer-motion";
import dfaimage from "../assets/dfaimage.png";
import mpassbook from "../assets/mpassbook.png";
import InfoFooter from "../components/InfoFooter";
import AlertDialog from "../components/AlertDialog";
import useDocumentTitle from "../components/DocTitle";

const OurPortfolio = () => {
  useDocumentTitle("Our Portfolio - Uranus Tech Nepal");

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const projects = [
    {
      name: "Core Banking Solution (CBS) for 30+ Institutions",
      description:
        "A comprehensive banking solution implemented in over 30 financial institutions.",
      imageUrl:
        "https://static.wixstatic.com/media/f9b0fa_917798574b3d4d978d0f515c2ba97235~mv2.jpg/v1/fill/w_640,h_360,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f9b0fa_917798574b3d4d978d0f515c2ba97235~mv2.jpg",
    },
    {
      name: "Human Resource Management",
      description:
        "An efficient HR management system to streamline HR processes.",
      imageUrl:
        "https://miro.medium.com/v2/resize:fit:1200/1*aoz09QCaLMxwTZ5osWuItw.jpeg",
    },
    {
      name: "Digital Field Application",
      description:
        "A mobile solution for field data collection and management.",
      imageUrl: dfaimage,
    },
    {
      name: "mPassbook",
      description:
        "A digital passbook application for convenient account management.",
      imageUrl: mpassbook,
    },
    {
      name: "NDIS Software Solution",
      description:
        "A software solution for managing National Disability Insurance Scheme (NDIS) operations.",
      imageUrl:
        "https://risucare.com.au/wp-content/uploads/2024/03/supported-independent-living-accommodation.webp",
    },
    {
      name: "ITSS",
      description: "ITSS",
      imageUrl:
        "https://english.onlinekhabar.com/wp-content/uploads/2016/07/Nrb-810.gif",
    },
    {
      name: "Pension Distribution System",
      description:
        "A system developed for efficient pension distribution by the Indian Embassy.",
      imageUrl:
        "https://newsroom.gy/wp-content/uploads/2016/08/pension_14735595_07_6_1469c.jpg",
    },
    {
      name: "Conference Management Web Solution with Mobile App",
      description:
        "A web and mobile solution for managing conferences organized by ACSIC.",
      imageUrl:
        "https://acsicnepal.com/static/media/bgpic.846dccfc9224360f9a31.jpg",
    },
  ];

  const handleLearnMore = (projectName) => {
    setAlertMessage(`More details about ${projectName} coming soon!`);
    setIsAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
    setAlertMessage("");
  };

  return (
    <div className="min-h-screen flex flex-col">
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10 flex-grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          Our Portfolio
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Explore some of the projects we've developed to help businesses
          succeed.
        </motion.p>

        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="relative bg-white border border-gray-200 shadow-md rounded-lg overflow-hidden transform transition-all duration-500 hover:shadow-lg hover:scale-105"
              whileHover={{ scale: 1.05 }}
            >
              <div className="relative w-full h-56 overflow-hidden group">
                <img
                  src={project.imageUrl}
                  alt={project.name}
                  className="w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-110"
                />
              </div>
              <div className="p-4 text-center">
                <h2 className="text-lg font-semibold text-gray-800 mb-2">
                  {project.name}
                </h2>
                <p className="text-sm text-gray-600">{project.description}</p>
                <button
                  onClick={() => handleLearnMore(project.name)}
                  className="mt-4 inline-block w-full text-center px-4 py-2 text-white bg-primary rounded-lg transition-transform duration-300 hover:scale-105 hover:bg-primary-dark"
                >
                  Learn More
                </button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {isAlertOpen && (
        <AlertDialog message={alertMessage} onClose={handleCloseAlert} />
      )}
      <InfoFooter />
    </div>
  );
};

export default OurPortfolio;
