import { createContext, useCallback, useState } from "react";
import { getAllStaffApi } from "../api/Api";

const StaffContext = createContext();

const StaffProvider = ({ children }) => {
  const [staff, setStaff] = useState([]);

  const getAllStaff = useCallback(async () => {
    try {
      const response = await getAllStaffApi();
      if (response.status === 200 || response.status === 201) {
        setStaff(response.data.staffData);
        console.log(response);
      }
    } catch (error) {
      console.log(`Error while fetching Staff from Context ${error}`);
      throw error;
    }
  }, []);

  return (
    <StaffContext.Provider value={{ staff, getAllStaff }}>
      {" "}
      {children}{" "}
    </StaffContext.Provider>
  );
};

export { StaffContext, StaffProvider };
export default StaffContext;
