import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Navbar from "./components/Navbar";
import Aboutus from "./pages/Aboutus";
import Clients from "./pages/Clients";
import OurTeam from "./pages/OurTeam";
import { StaffProvider } from "./context/teamContext";
import LoginPage from "./components/LoginPage";
import Careers from "./pages/Careers";
import OurPortfolio from "./pages/OurPortfolio";
import Testimonials from "./pages/Testimonials";
import ScrollToTop from "./utils/ScrollToTop";
import RegisterPage from "./pages/RegisterPage";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <StaffProvider>
        <BrowserRouter>
          <Toaster position="top-right" reverseOrder={false} /> <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Homepage />} />{" "}
            <Route path="/about" element={<Aboutus />} />{" "}
            <Route path="/clients" element={<Clients />} />{" "}
            <Route path="/teams" element={<OurTeam />} />{" "}
            <Route path="/login" element={<LoginPage />} />{" "}
            <Route path="/careers" element={<Careers />} />{" "}
            <Route path="/products" element={<OurPortfolio />} />{" "}
            <Route path="/testimonials" element={<Testimonials />} />{" "}
            <Route path="/register" element={<RegisterPage />} />{" "}
          </Routes>{" "}
        </BrowserRouter>{" "}
      </StaffProvider>{" "}
    </div>
  );
}

export default App;
