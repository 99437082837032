import React, { useState } from "react";
import { registerApi } from "../api/Api";
import toast from "react-hot-toast";
import { FaEnvelope, FaPhoneAlt, FaLocationArrow } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import zxcvbn from "zxcvbn";
import logo from "../assets/logo.png";
import AlertDialog from "../components/AlertDialog";
import useDocumentTitle from "../components/DocTitle";

const RegisterPage = () => {
  useDocumentTitle("Register - URANUS TECH NEPAL");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    userName: "",
    password: "",
    phoneNumber: "",
    officeNumber: "",
    organizationName: "",
    designation: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullname.trim()) {
      newErrors.fullname = "Full name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!formData.userName.trim()) {
      newErrors.userName = "Username is required";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }
    if (!formData.organizationName.trim()) {
      newErrors.organizationName = "Organization name is required";
    }
    if (!formData.designation.trim()) {
      newErrors.designation = "Designation is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await registerApi(formData);
      const message = response.data.message;
      toast.success(message || "Registration successful!");
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || "Registration failed.";
      console.error("Registration error:", errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setFormData({
      ...formData,
      password: password,
    });
    const evaluation = zxcvbn(password);
    setPasswordStrength(evaluation.score);
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-4xl w-full bg-white bg-opacity-90 shadow-xl rounded-lg p-8">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>

        <h2 className="text-center text-3xl font-bold text-gray-800">
          Create Your Account
        </h2>
        <p className="text-center text-sm text-gray-600 mt-2">
          Already have an account?{" "}
          <a
            href="/login"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Sign in
          </a>
        </p>

        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="fullname" className="sr-only">
                Full Name
              </label>
              <input
                id="fullname"
                name="fullname"
                type="text"
                value={formData.fullname}
                onChange={handleChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.fullname ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Full Name"
              />
              {errors.fullname && (
                <p className="text-red-500 text-xs mt-1">{errors.fullname}</p>
              )}
            </div>

            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Email"
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <label htmlFor="userName" className="sr-only">
                Username
              </label>
              <input
                id="userName"
                name="userName"
                type="text"
                value={formData.userName}
                onChange={handleChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.userName ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Username"
              />
              {errors.userName && (
                <p className="text-red-500 text-xs mt-1">{errors.userName}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handlePasswordChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Password"
              />
              {errors.password && (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              )}
              <div className="mt-2">
                <PasswordStrengthMeter strength={passwordStrength} />
              </div>
            </div>

            <div>
              <label htmlFor="phoneNumber" className="sr-only">
                Phone Number
              </label>
              <PhoneInput
                country={"np"}
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputClass="!w-full !rounded-md !border-gray-300 focus:!ring-blue-500 focus:!border-blue-500"
                containerClass="w-full"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: false,
                }}
                inputStyle={{
                  width: "100%",
                  height: "2.5rem",
                  borderRadius: "0.375rem",
                }}
                enableAreaCodes
                enableLongNumbers
              />
            </div>

            <div>
              <label htmlFor="officeNumber" className="sr-only">
                Office Number
              </label>
              <input
                id="officeNumber"
                name="officeNumber"
                type="text"
                value={formData.officeNumber}
                onChange={handleChange}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md"
                placeholder="Office Number"
              />
            </div>

            <div>
              <label htmlFor="organizationName" className="sr-only">
                Organization Name
              </label>
              <input
                id="organizationName"
                name="organizationName"
                type="text"
                value={formData.organizationName}
                onChange={handleChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.organizationName ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Organization Name"
              />
              {errors.organizationName && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.organizationName}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="designation" className="sr-only">
                Designation
              </label>
              <input
                id="designation"
                name="designation"
                type="text"
                value={formData.designation}
                onChange={handleChange}
                className={`appearance-none block w-full px-3 py-2 border ${
                  errors.designation ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm rounded-md`}
                placeholder="Designation"
              />
              {errors.designation && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.designation}
                </p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition ease-in-out duration-150"
            >
              Sign Up
            </button>
          </div>
        </form>

        <div className="mt-8 text-center text-sm text-gray-600">
          <p className="flex items-center justify-center">
            <FaPhoneAlt className="mr-2" /> 01-5244670
            <span className="mx-4">|</span> <FaEnvelope className="mr-2" />{" "}
            info@uranustechnepal.com <span className="mx-4">|</span>{" "}
            <FaLocationArrow className="mr-2" />
            Bijulibazar-10, Kathmandu, Nepal
          </p>
        </div>
      </div>

      {isDialogOpen && (
        <AlertDialog
          message="This feature is not available. Check back later."
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

const PasswordStrengthMeter = ({ strength }) => {
  const strengthLabels = ["Weak", "Fair", "Good", "Strong", "Very Strong"];
  const strengthColors = [
    "#e74c3c",
    "#f39c12",
    "#f1c40f",
    "#27ae60",
    "#2ecc71",
  ];

  return (
    <div className="flex items-center mt-1">
      <div
        className="w-full h-2 rounded bg-gray-300"
        style={{
          backgroundColor: strengthColors[strength],
        }}
      ></div>
      <span className="ml-3 text-sm text-gray-600">
        {strengthLabels[strength]}
      </span>
    </div>
  );
};

export default RegisterPage;
