import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { getFeedbackApi } from "../api/Api";
import InfoFooter from "../components/InfoFooter";
import Lottie from "react-lottie";
import assetImage from "../assets/logo.png";
import errorAnimation from "../assets/animation/error_connection.json";

const Testimonials = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        setLoading(true);
        const response = await getFeedbackApi();
        setFeedbacks(response.data.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to load feedbacks.");
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FaStar key={i} className="text-yellow-500" />
          ))}
        {halfStar && <FaStarHalfAlt className="text-yellow-500" />}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FaRegStar key={i} className="text-yellow-500" />
          ))}
      </>
    );
  };

  const getInitials = (name) => {
    if (!name) return "N/A";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="min-h-screen flex flex-col">
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10 flex-grow"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-4"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          What Our Clients Say
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          We take pride in delivering great service to our clients. Read what
          they have to say about our work.
        </motion.p>

        {loading && (
          <div className="text-center text-gray-600">Loading feedback...</div>
        )}

        {error && (
          <div className="flex flex-col items-center">
            <Lottie options={defaultOptions} height={150} width={150} />
            <div className="text-center text-red-500 mt-4">{error}</div>
          </div>
        )}

        {!loading && !error && feedbacks.length > 0 && (
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {feedbacks.map((feedback, index) => (
              <motion.div
                key={index}
                className="shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 bg-white p-6"
                whileHover={{ scale: 1.05 }}
              >
                <div className="flex items-center mb-4">
                  <img
                    src={feedback.imageUrl || assetImage}
                    alt={feedback.name ? getInitials(feedback.name) : "Unknown"}
                    className="w-16 h-16 rounded-full object-contain mr-4"
                  />
                  <div>
                    <h2 className="text-xl font-semibold text-black">
                      {feedback.name}
                    </h2>
                    <p className="text-sm text-gray-600">
                      {feedback.designation || "N/A"}
                    </p>
                    <div className="flex items-center mt-2">
                      {renderStars(feedback.rating)}
                    </div>
                  </div>
                </div>
                <p className="text-gray-800">{feedback.feedback}</p>
              </motion.div>
            ))}
          </motion.div>
        )}

        {!loading && !error && feedbacks.length === 0 && (
          <div className="text-center text-gray-600">No data available.</div>
        )}
      </motion.div>
      <InfoFooter />
    </div>
  );
};

export default Testimonials;
