import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const TrustedBy = () => {
  const logos = [
    {
      name: "NRB",
      imageUrl:
        "https://upload.wikimedia.org/wikipedia/commons/9/9e/Seal_of_the_Nepal_Rastra_Bank.jpg",
    },
    {
      name: "DCGF",
      imageUrl:
        "https://dcgf.gov.np/front/nep/images/deposit-and-credit-guarantee-fund-small-logo.jpg",
    },
    {
      name: "NIC",
      imageUrl:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/a7/5c/7a/a75c7aa6-af62-cca0-6117-02d8ac4435b0/AppIcon-0-0-1x_U007emarketing-0-6-0-0-85-220.png/512x512bb.jpg",
    },
    {
      name: "Indian EMB",
      imageUrl:
        "https://www.indembkathmandu.gov.in/themes/w3cms/embassy-of-india/assets/images/logo.png",
    },
    {
      name: "Deprosc",
      imageUrl:
        "https://play-lh.googleusercontent.com/wC_z0UJ-peI_vnE0IFUlLvbV3mMEcxwr6xbnrLhuDxIYRgRUT4HU46Ue9VYM3puYjE8=w240-h480-rw",
    },
    {
      name: "Mahuli",
      imageUrl: "https://mslbsl.com.np/images/logo.png",
    },
    {
      name: "Forward",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc_l28eISorl_lFtoi8_-Nf0QtOifrATEtgNjjBVVv6Mo6s0PCz_PO6H8B4oYWHsYI38Y&usqp=CAU",
    },
    {
      name: "SWBBL",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMlTCw_BO35RqD9Hli4VvOP6PHdaBNmTQDSw&s",
    },
    {
      name: "BGMCL",
      imageUrl: "https://csdnepal.org.np/app/webroot/upload/images/BGMCL.jpg",
    },
    {
      name: "Gurans",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnhUZ8f3yuWmWybgcCL13iA-87Bwm-NpKGxw&s",
    },
    {
      name: "SKBBL",
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbZu2roQgmSA8qaXCUaFQ5SD1i2Pb0R0Igvg&s",
    },
    {
      name: "NMB",
      imageUrl:
        "https://yt3.googleusercontent.com/66MAIe7FVClHq0hGdE1zrPvtjl7F3V6c1c6PNYjM-r0umv8r9-4WXLbX36fye_ILJ1BPZciLig=s900-c-k-c0x00ffffff-no-rj",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <div className="mt-10 px-2 sm:px-4 py-10 bg-gray-100">
      <h1 className="font-semibold text-primarytext text-2xl md:text-3xl text-center mb-2">
        We are Trusted By
      </h1>
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        Our clients and partners include some of the most trusted brands.
      </p>
      <div className="max-w-full mx-auto">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={false}
          className="relative"
          containerClass="carousel-container px-0"
        >
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center justify-center p-2">
              <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4 flex items-center justify-center w-24 h-24 md:w-28 md:h-28 lg:w-32 lg:h-32">
                <img
                  src={logo.imageUrl}
                  alt={logo.name}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TrustedBy;
