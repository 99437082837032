import React from "react";
import { motion } from "framer-motion";
import { FaHeartbeat, FaClock, FaChartLine, FaUsers } from "react-icons/fa";
import useDocumentTitle from "../components/DocTitle";
import contactImage from "../assets/joinus.png";
import InfoFooter from "../components/InfoFooter";

const Careers = () => {
  useDocumentTitle("Careers - URANUS TECH NEPAL");

  return (
    <>
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          Careers at Uranus Tech Nepal
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Join our team of innovators and help us create solutions that make a
          difference. Explore our current job openings and learn more about what
          it's like to work at Uranus Tech Nepal.
        </motion.p>

        {/* Company Culture Section */}
        <motion.div
          className="mb-16"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="font-semibold text-3xl text-primarytext text-center mb-6">
            Our Company Culture
          </h2>
          <motion.div
            className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex items-center"
            whileHover={{ scale: 1.05 }}
          >
            <FaUsers className="text-primary text-5xl mr-6" />
            <div>
              <h3 className="font-semibold text-xl mb-2">
                Collaborative Environment
              </h3>
              <p className="text-gray-700">
                At{" "}
                <span className="font-bold text-primary">
                  Uranus Tech Nepal
                </span>
                , we foster a culture of
                <span className="font-bold"> collaboration</span>,{" "}
                <span className="font-bold">creativity</span>, and
                <span className="font-bold"> growth</span>. We empower our
                employees to take ownership of their work, encourage innovation,
                and provide opportunities for continuous learning. Whether
                you’re just starting your career or looking to take the next
                step, you’ll find a welcoming environment where you can thrive.
              </p>
            </div>
          </motion.div>
        </motion.div>

        {/* Benefits Section */}
        <motion.div
          className="mb-16"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="font-semibold text-3xl text-primarytext text-center mb-6">
            Benefits of Working with Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Health & Wellness */}
            <motion.div
              className="text-left shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex items-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaHeartbeat className="text-red-500 text-5xl mr-6" />
              <div>
                <h3 className="font-semibold text-xl mb-2">
                  Health & Wellness
                </h3>
                <p className="text-gray-700">
                  We offer comprehensive health insurance plans and wellness
                  programs to support your physical and mental well-being.
                </p>
              </div>
            </motion.div>

            {/* Flexible Work Hours */}
            <motion.div
              className="text-left shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex items-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaClock className="text-blue-500 text-5xl mr-6" />
              <div>
                <h3 className="font-semibold text-xl mb-2">
                  Flexible Work Hours
                </h3>
                <p className="text-gray-700">
                  We understand the importance of work-life balance, which is
                  why we offer flexible work hours and remote work options.
                </p>
              </div>
            </motion.div>

            {/* Career Growth */}
            <motion.div
              className="text-left shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300 flex items-center"
              whileHover={{ scale: 1.05 }}
            >
              <FaChartLine className="text-green-500 text-5xl mr-6" />
              <div>
                <h3 className="font-semibold text-xl mb-2">Career Growth</h3>
                <p className="text-gray-700">
                  We invest in your professional development with training
                  programs, workshops, and opportunities for career advancement.
                </p>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Job Openings Section */}
        <motion.div
          className="mb-16"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="font-semibold text-3xl text-primarytext text-center mb-6">
            Current Job Openings
          </h2>
          <p className="text-center text-gray-700 text-lg">
            There are no job openings at the moment. Please check back later!
          </p>

          {/* Uncomment below when vacancies are available */}
          {/*
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="font-semibold text-xl text-primary mb-2">
                Software Engineer
              </h3>
              <p className="text-gray-700 mb-4">
                We are looking for a skilled Software Engineer to join our team.
                You will work on developing cutting-edge technology solutions in
                a collaborative and fast-paced environment.
              </p>
              <button className="bg-primary text-white px-4 py-2 rounded">
                Apply Now
              </button>
            </motion.div>
            <motion.div
              className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="font-semibold text-xl text-primary mb-2">
                Junior .NET Developer
              </h3>
              <p className="text-gray-700 mb-4">
                We are looking for a skilled Software Engineer to join our team.
                You will work on developing cutting-edge technology solutions in
                a collaborative and fast-paced environment.
              </p>
              <button className="bg-primary text-white px-4 py-2 rounded">
                Apply Now
              </button>
            </motion.div>
          </div>
          */}
        </motion.div>

        {/* Call to Action */}
        <motion.div
          className="flex flex-col md:flex-row items-center md:justify-between text-center md:text-left"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="font-semibold text-3xl text-primarytext mb-4">
              Ready to Join Us?
            </h2>
            <p className="text-gray-700 text-lg mb-8">
              If you're passionate about technology and innovation, and you want
              to make a real impact, we want to hear from you. Explore our open
              positions or get in touch with us to learn more about life at
              Uranus Tech Nepal.
            </p>
            <button className="bg-primary text-white px-6 py-3 rounded">
              Contact Us
            </button>
          </div>
          <div className="md:w-1/3">
            <img
              src={contactImage}
              alt="Contact Us"
              className="w-full h-auto"
            />
          </div>
        </motion.div>
      </motion.div>

      <InfoFooter />
    </>
  );
};

export default Careers;
