import React, { useState, useEffect, useMemo } from "react";
import Lottie from "react-lottie";
import animationData1 from "../assets/animation/lottie1.json";
import animationData2 from "../assets/animation/app_animation.json";
import animationData3 from "../assets/animation/web_lottie.json";

const DashboardAnimation = () => {
  const animations = useMemo(
    () => [animationData1, animationData2, animationData3],
    []
  );

  const [animationIndex, setAnimationIndex] = useState(0);
  const [animationData, setAnimationData] = useState(animations[0]);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    setFade(false);
    const timer = setTimeout(() => {
      setAnimationData(animations[animationIndex]);
      setFade(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [animationIndex, animations]);

  useEffect(() => {
    const changeAnimation = () => {
      const nextIndex = (animationIndex + 1) % animations.length;
      setAnimationIndex(nextIndex);
    };

    const timer = setTimeout(changeAnimation, 5000);

    return () => clearTimeout(timer);
  }, [animationIndex, animations]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex justify-center items-center w-full">
      {/* Consistent Container */}
      <div
        className="w-[300px] h-[300px] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px] transition-opacity duration-500"
        style={{
          opacity: fade ? 1 : 0,
        }}
      >
        <Lottie
          options={defaultOptions}
          isStopped={!fade}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default DashboardAnimation;
