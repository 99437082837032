import axios from "axios";

const Api = axios.create({
  baseURL: "http://localhost:5500/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export const loginApi = (email, password) =>
  Api.post("/user/login", { email, password });

export const registerApi = (userData) => Api.post("/user/register", userData);

export const getUserProfileApi = (id) =>
  Api.get(`/user/${id}`, getAuthHeaders());

// -------------------- Get all Staff ---------------------//
export const getAllStaffApi = () => Api.get("/staff/getallstaff");

// -------------------- Get Feedback ---------------------//
export const getFeedbackApi = () => Api.get("/feedback/get-feedbacks");
